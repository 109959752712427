* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 1rem;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white;
}

h1 {
  font-weight: 800;
  font-size: 1.5rem;
}


.Baap{

height: 80vh;

display: grid;
grid-template-rows: repeat(3, 0.2fr);
justify-content: center;
align-items: center;
border: 2px solid red;

}


@media (min-width: 400px){


    .Baap{
     height: 90vh;
     border: 2px solid green;




    }






}



